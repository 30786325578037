export const Route = {
  WELCOME: "/welcome",
  VERIFY: "/verify",
  NOT_FOUND: "*",
  UNAUTHORIZED: "/unauthorized",
  DASHBOARD: "/dashboard",
  ORGANIZATION: "/organization",
  LIST_ORGANIZATION: "/list-organization",
  LIST_SUPER_ADMIN: "/list-super-admin",
};
