import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Role } from "../utils/enums/roles.enum";

export interface AddEditUserProps {
  open: boolean;
  role: Role;
  title: string;
  action: "Create" | "Save";
  id?: string;
  trigger?: boolean;
}

const initialState: AddEditUserProps = {
  open: false,
  role: Role.SUPER_ADMIN,
  title: "Create Super Admin",
  action: "Create",
  trigger: false,
};

export const AddEditUserReducer = createSlice({
  name: "addEditUser",
  initialState,
  reducers: {
    addEditUser: (state, action: PayloadAction<AddEditUserProps>) =>
      action.payload,
    closeAddEditUser: (state, action: PayloadAction<{ trigger?: boolean }>) => {
      return {
        ...initialState,
        trigger: action.payload.trigger || false,
      };
    },
  },
});

export const { addEditUser, closeAddEditUser } = AddEditUserReducer.actions;
export default AddEditUserReducer.reducer;
