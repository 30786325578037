// RenderRoutes.tsx
import React from "react";
import { lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Route as CustomRoute } from "../utils/Route";

//project import
import RouteGuard from "./RouteGuard";
import { RouteConfig } from "./types";
import Loadable from "../components/Loadable";
import Unauthorized from "../pages/unauthorized";
import NoPageFound from "../pages/NoPageFound";

// layout
import AuthLayout from "../pages/auth/authLayout";

// render - login
const Welcome = Loadable(lazy(() => import("../pages/auth/welcome")));
const Verify = Loadable(lazy(() => import("../pages/auth/verify")));

const Layout = Loadable(lazy(() => import("../components/layout")));

interface RenderRoutesProps {
  routes: RouteConfig;
}

const RenderRoutes: React.FC<RenderRoutesProps> = ({ routes }) => {
  return (
    <Routes>
      <Route path="/" element={<AuthLayout />}>
        <Route
          path="/"
          element={<Navigate to={CustomRoute.WELCOME} replace />}
        />
        <Route path={CustomRoute.WELCOME} element={<Welcome />} />
        <Route path={CustomRoute.VERIFY} element={<Verify />} />
      </Route>

      <Route element={<Layout />}>
        {routes.children &&
          routes.children.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <RouteGuard allowedRoles={route.roles || []}>
                    {route.element}
                  </RouteGuard>
                }
              />
            );
          })}
      </Route>

      <Route path={CustomRoute.NOT_FOUND} element={<NoPageFound />} />
      <Route path={CustomRoute.UNAUTHORIZED} element={<Unauthorized />} />
    </Routes>
  );
};

export default RenderRoutes;
