// routesConfig.tsx
import { lazy } from "react";

// project import
import Loadable from "../components/Loadable";
import { RouteConfig } from "./types";

import { Role } from "../utils/enums/roles.enum";
import { Route } from "../utils/Route";

const Layout = Loadable(lazy(() => import("../components/layout")));
const Dashboard = Loadable(lazy(() => import("../pages/dashboard")));
const CreateEditViewOrgainization = Loadable(
  lazy(() => import("../pages/common/organization/create-edit-view"))
);
const ListOrgainization = Loadable(
  lazy(() => import("../pages/common/organization/list-organizations"))
);
const ListSuperAdmin = Loadable(
  lazy(() => import("../pages/common/user/list-users"))
);

export const routesConfig: RouteConfig = {
  path: "/",
  element: <Layout />,
  children: [
    {
      path: Route.DASHBOARD,
      element: <Dashboard />,
      roles: [Role.PLATFORM_OWNER, Role.SUPER_ADMIN],
    },
    {
      path: Route.ORGANIZATION,
      element: <CreateEditViewOrgainization />,
      roles: [Role.PLATFORM_OWNER],
    },
    {
      path: Route.LIST_ORGANIZATION,
      element: <ListOrgainization />,
      roles: [Role.PLATFORM_OWNER],
    },
    {
      path: Route.LIST_SUPER_ADMIN,
      element: <ListSuperAdmin />,
      roles: [Role.PLATFORM_OWNER],
    },
  ],
};
